import React from 'react';

function About1() {
  return (
    <section className="tc-about-style3">
      <div className="top-info">
        <div className="container-fluid"> {/* Full-width layout */}
          <div className="row">
            <div className="col-lg-12"> {/* Full-width column */}
              <div className="info">
                {/* Milestone Section with Slide-Up Image */}
                <div className="image-container">
                  <img
                    src="/innerpages/assets/img/about/03.jpg" // Replace with your actual image path
                    alt="Milestone Image"
                    className="milestone-image"
                  />
                </div>
                <a
                  href="/innerpages/about"
                  className="butn borderd mt-70 bg-white py-2 px-3 text-uppercase ltspc-1 hover-bg-yellow1"
                >
                  {/* Call to action button */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .container-fluid {
          padding: 0;
        }

        .image-container {
          overflow: hidden; /* Ensures the image stays within bounds during the animation */
          width: 100%;
        }

        .milestone-image {
          width: 100%; /* Full width of the container */
          height: auto; /* Maintain aspect ratio */
          display: block;
          transform: translateY(0); /* Initial position */
          transition: transform 0.5s ease-in-out; /* Smooth transition for the sliding effect */
        }

        .image-container:hover .milestone-image {
          transform: translateY(-30px); /* Slide the image up by 30px on hover */
        }

        @media (max-width: 768px) {
          .milestone-image {
            width: 100%; /* Full width on mobile */
            height: auto; /* Maintain aspect ratio */
          }
        }
      `}</style>
    </section>
  );
}

export default About1;
