import React from 'react';

function Footer() {
  return (
    <footer className="tc-footer-style3" style={{ padding: '20px 0', backgroundColor: 'black' }}>
      <div className="container">
        <div className="main-content" style={{ marginBottom: '10px' }}>
          <div className="row align-items-center" style={{ justifyContent: 'space-between' }}>
            
            <div className="col-lg-5 col-md-6 mb-3">
              <div className="adresses">
                <div className="adress-card">
                  <h5 className="fsz-24 text-uppercase fw-500 mb-2" style={{ fontSize: '20px', color: '#fff' }}>
                    Financial District, Hyderabad
                  </h5>
                  <ul className="color-999" style={{ fontSize: '16px', color: '#fff', paddingLeft: '0' }}>
                    <li>
                      <a href="https://maps.app.goo.gl/VmaqDDkbfLeMMd5v5" style={{ color: '#fff' }}>
                        The District, Level 12, Near Regus
                      </a>
                    </li>
                    <li>
                      <a href="mailto:sarvaraya@casafortune.in" style={{ color: '#fff' }}>
                        sarvaraya@casafortune.in
                      </a>
                    </li>
                    <li>
                      <a href="tel:+919550485888" style={{ color: '#fff' }}>
                        +91 95504 85888
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-2 text-right">
              <div className="social">
                <h5 className="fsz-24 text-uppercase fw-500 mb-2" style={{ fontSize: '20px', color: '#fff' }}>
                  Follow Us
                </h5>
                <div className="social-links">
                  <a href="#" style={{ marginRight: '10px', fontSize: '24px', color: '#fff' }}>
                    <i className="lab la-twitter"></i>
                  </a>
                  <a href="#" style={{ marginRight: '10px', fontSize: '24px', color: '#fff' }}>
                    <i className="lab la-facebook-f"></i>
                  </a>
                  <a href="#" style={{ marginRight: '10px', fontSize: '24px', color: '#fff' }}>
                    <i className="lab la-instagram"></i>
                  </a>
                  <a href="#" style={{ fontSize: '24px', color: '#fff' }}>
                    <i className="lab la-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="foot" style={{ paddingTop: '5px' }}>
          <div className="row align-items-center">
            <div className="col-lg-12 text-center">
              <p className="color-999" style={{ fontSize: '14px', color: '#fff' }}>
                © 2024 CASAFORTUNE. All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
