import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Mock data for the banners
const bannerData = [
  {
    id: 1,
    image: '/home_preview/assets/img/3.jpg',
    alt: 'Banner 1',
    caption: '', // Set caption to an empty string for the logo
    logo: '/home_preview/assets/img/logo.png', // Path to the Casa logo
  },
  {
    id: 2,
    image: '/home_preview/assets/img/2.jpg',
    alt: 'Banner 2',
    caption: 'From Blueprints to Beautiful Realities. Casa Fortune - Where Imagination takes a Real Shape',
  },
  {
    id: 3,
    image: '/home_preview/assets/img/1.jpg',
    alt: 'Banner 3',
    caption: 'Designing Destinies, Building Dreams. Casa Fortune - Where Every Detail Matters',
  },
];

function Header() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    arrows: true,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <header className="tc-header-preview">
      <div
        className="slider-card"
        style={{
          position: 'relative',
          height: '800px',
          maxHeight: '800px',
          overflow: 'hidden',
        }}
      >
        <Slider {...sliderSettings}>
          {bannerData.map((banner, index) => (
            <div key={banner.id} style={{ position: 'relative', height: '100%' }}>
              {/* Background image with Ken Burns effect */}
              <div
                style={{
                  width: '100%',
                  height: '800px',
                  backgroundImage: `url(${banner.image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  transition: 'transform 20s ease-out',
                  filter: 'brightness(0.85)',
                  transform: currentSlide === index ? 'scale(1.1)' : 'scale(1)',
                }}
              />

              {/* Caption or logo container with animation */}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 3,
                  textAlign: 'center',
                  opacity: currentSlide === index ? 1 : 0,
                  transition: 'opacity 1.5s ease-in-out',
                }}
              >
                {index === 0 ? (
                  <img
                    src={banner.logo}
                    alt="Casa Fortune Logo"
                    style={{
                      maxWidth: '500px', // Adjust size
                      animation: 'fadeIn 2s ease-in-out',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      background: 'rgba(255, 255, 255, 0.85)',
                      borderRadius: '10px',
                      padding: '10px 20px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    <h2 style={{ margin: 0, fontSize: '20px', lineHeight: '1.5' }}>
                      {banner.caption}
                    </h2>
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </header>
  );
}

export default Header;
