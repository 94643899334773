import React from 'react';

function Testingsection() {
  const services = [
    { title: 'Design Consultancy', img: '/home3_construction/assets/img/testing/ser1.jpg' },
    { title: 'Building Construction', img: '/home3_construction/assets/img/testing/ser2.jpg' },
    { title: 'Interior Design & Fitout', img: '/home3_construction/assets/img/testing/ser3.jpg' },
    { title: 'Carpentry work & Joinery', img: '/home3_construction/assets/img/testing/ser4.jpg' },
    { title: 'MEP Design & HVAC', img: '/home3_construction/assets/img/testing/ser5.jpg' },
    { title: 'Turnkey Solutions', img: '/home3_construction/assets/img/testing/ser6.jpg' },
    { title: 'Furniture Design', img: '/home3_construction/assets/img/testing/ser7.jpg' },
    { title: 'Commercial Kithcen Design & Build', img: '/home3_construction/assets/img/testing/ser8.jpg' }

  ];

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Adjusted for transparency
    border: '1px solid rgba(0, 0, 0, 0.1)', // Slightly transparent border
    borderRadius: '15px',
    overflow: 'hidden',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    padding: '0',
    marginBottom: '20px', // Margin between cards in the same row
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  };

  const imgStyle = {
    width: '100%',
    height: '280px', // Increased image height to occupy more space
    objectFit: 'cover',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    transition: 'transform 0.3s ease-in-out',
  };

  const infoStyle = {
    padding: '8px 10px', // Further reduced padding in the text area
    textAlign: 'center',
  };

  const infoHeadingStyle = {
    fontSize: '18px', // Slightly smaller font size for the text
    fontWeight: '600',
    color: '#333',
    marginTop: '5px', // Reduced top margin
    marginBottom: '0',
    transition: 'color 0.3s ease-in-out',
  };

  const serviceCardHover = (e) => {
    e.currentTarget.style.transform = 'translateY(-10px)';
    e.currentTarget.style.boxShadow = '0 15px 25px rgba(0, 0, 0, 0.2)';
    e.currentTarget.querySelector('img').style.transform = 'scale(1.1)';
    e.currentTarget.querySelector('h3').style.color = '#007bff'; // Change heading color on hover
  };

  const serviceCardUnhover = (e) => {
    e.currentTarget.style.transform = 'translateY(0)';
    e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
    e.currentTarget.querySelector('img').style.transform = 'scale(1)';
    e.currentTarget.querySelector('h3').style.color = '#333';
  };

  return (
    <section className="tc-services-style3">
      <div className="container">
        <div className="top-inf mb-80">
          <div className="row">
            <div className="col-lg-4">
              <h5
                className="fsz-30 text-uppercase wow fadeInUp slow"
                data-wow-delay="0.2s"
              >
                Our Services
              </h5>
            </div>
            <div className="col-lg-4">
              <div
                className="text fsz-28 mt-4 mt-lg-8 pe-lg-18 wow fadeInUp slow"
                data-wow-delay="0.4s"
              >
                We Have Solutions for All Your Space Related Issues!
              </div>
            </div>
          </div>
        </div>

        {/* Services Cards Section */}
        <div className="services-cards">
          <div className="row gx-2"> {/* Reduced horizontal gap */}
            {services.map((service, index) => (
              <div className="col-md-4 mb-4" key={index}> {/* Added mb-4 for vertical gap */}
                <a
                  href="/innerpages/services"
                  className="service-card wow fadeInUp"
                  data-wow-delay={`${0.2 + (index % 3) * 0.2}s`}
                  onMouseEnter={serviceCardHover}
                  onMouseLeave={serviceCardUnhover}
                  style={cardStyle}
                >
                  <div className="img">
                    <img
                      src={service.img}
                      alt={service.title}
                      style={imgStyle}
                    />
                  </div>
                  <div style={infoStyle}>
                    <h3 style={infoHeadingStyle}>
                      {service.title}
                    </h3>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testingsection;
