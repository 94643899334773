import React from 'react';

function Footer() {
  return (
    <footer className="tc-footer-style1 border-top">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                
                <div className="foot-social mt-50">
                  <a href="#">
                    <i className="fab fa-x-twitter" style={{ fontSize: '24px' }}></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook-f" style={{ fontSize: '24px' }}></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram" style={{ fontSize: '24px' }}></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in" style={{ fontSize: '24px' }}></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600"> Financial District, Hyderabad </h5>
                <ul className="footer-links">
                  <li>
                    <a href="https://maps.app.goo.gl/VmaqDDkbfLeMMd5v5">Level 12, The District, Financial District, Hyderabad, Telangana </a>
                  </li>
                  <li>
                    <a href="mailto:sarvaraya@casafortune.in"> sarvaraya@casafortune.in </a>
                  </li>
                  <li>
                    <a href="tel:+919550485888"> +91 95504 85888</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© 2024 CASAFORTUNE. All Right Reserved</p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <a href="/"> Home </a>
                <a href="/innerpages/about"> About Us </a>
                <a href="/innerpages/services"> Services </a>
                <a href="/innerpages/portfolio"> Projects </a>
                <a href="/innerpages/contact"> Contact </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
