import React from 'react';

function Header() {
  return (
    <header className="tc-inner-header-style1 pb-100">
      <div className="container">
        <div className="info">
        <h1 style={{ color: 'red', fontSize: '180px' }}>Contact</h1>
          <div className="text fsz-18 color-666">
            Want to chat further? We’d love to hear from you, let us take the oppoturnity{' '}
            <br /> to make your dream become reality{' '}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
