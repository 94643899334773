import React, { useState } from 'react';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Inline styles for the navbar with a white background and sticky behavior
  const navbarStyles = {
    position: 'fixed', // Fixed positioning for sticky effect
    top: '0', // Stick to the top
    width: '100%', // Full width
    zIndex: 1000, // Ensure it's on top of other elements
    backgroundColor: 'white', // Background color
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
    padding: '5px 0', // Reduce padding for a smaller navbar height
  };

  const containerStyles = {
    display: 'flex',
    justifyContent: 'flex-start', // Align items to the left
    alignItems: 'center', // Center items vertically
    padding: '5px 80px', // Reduced padding to make navbar smaller
  };

  const logoContainerStyles = {
    display: 'flex', // Use flexbox for logo alignment
    justifyContent: 'flex-start', // Align logo to the left
    alignItems: 'center', // Center logo vertically with the menu items
  };

  const logoStyles = {
    height: '40px', // Reduce logo height to make the navbar smaller
    marginRight: '20px', // Space between the logo and the menu
  };

  const ulStyles = {
    listStyleType: 'none',
    padding: 0,
    display: 'flex', // Display items in a row
    margin: 0, // Remove default margin
    marginLeft: '40px', // Add margin to push the menu items to the right
  };

  const liStyles = {
    margin: '0 15px', // Space between menu items
  };

  const aStyles = {
    color: '#333', // Link color for contrast against the white background
    textDecoration: 'none',
    fontSize: '18px', // Reduced font size
    fontWeight: 'bold', // Bold font
    transition: 'color 0.3s ease, transform 0.3s ease', // Smooth transition for hover effect
  };

  // Hover animation
  const hoverStyles = {
    ':hover': {
      color: '#ff6347', // Change color on hover
      transform: 'scale(1.1)', // Slightly enlarge the text on hover
    },
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light inner-navbar-style1" style={navbarStyles}>
      <div className="container-fluid" style={containerStyles}>
        <div style={logoContainerStyles}>
          <a className="navbar-brand" href="#">
            <img
              src="/home3_construction/assets/img/logo.png"
              alt="Logo"
              style={logoStyles}
            />
          </a>

          <div className="nav-menu">
            <ul style={ulStyles}>
              <li style={liStyles}><a href="/" style={{ ...aStyles, ...hoverStyles }}>Home</a></li>
              <li style={liStyles}><a href="/innerpages/about" style={{ ...aStyles, ...hoverStyles }}>About</a></li>
              <li style={liStyles}><a href="/innerpages/services" style={{ ...aStyles, ...hoverStyles }}>Services</a></li>
              <li style={liStyles}><a href="/innerpages/portfolio" style={{ ...aStyles, ...hoverStyles }}>Recent Works</a></li>
              <li style={liStyles}><a href="/innerpages/contact" style={{ ...aStyles, ...hoverStyles }}>Contact</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
