import React from 'react';

function Header() {
  return (
    <header className="tc-inner-header-style1">
      <div className="container">
        
      </div>
    </header>
  );
}

export default Header;
