import React from 'react';

function PhilosophySection() {
  return (
    <section className="tc-philosophy-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side: Image */}
          <div className="col-lg-6">
            <div className="philosophy-image-container">
              <img
                src="/innerpages/assets/img/final.png" // Replace with your actual image path
                alt="Philosophy Image"
                className="philosophy-image"
              />
            </div>
          </div>

          {/* Right Side: Heading and Content */}
          <div className="col-lg-6">
            <div className="philosophy-content">
              <h2 className="philosophy-heading">Our Philosophy</h2>
              <p className="philosophy-text">
                Your Construction design and interiors should be a reflection of you and your lifestyle.
                We respect your budget and customize solutions that work within it.
                Our personal involement will be thorough, providing you with an in-depth understanding of our process and design work.
              </p>
              <p className="philosophy-text">
                We believe that constant attention to detail is highly crucial to ensure both functionally useful and aesthetically appealing work and residential projects.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .tc-philosophy-section {
          padding: 80px 0; /* Add some padding to the section */
        }

        .philosophy-image-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .philosophy-image {
          width: 80%; /* Reduce image width to scale the logo down */
          max-width: 400px; /* Set a max-width to prevent it from being too large */
          height: auto; /* Maintain aspect ratio */
          border-radius: 8px; /* Optional rounded corners */
        }

        .philosophy-content {
          text-align: left; /* Ensure left alignment for the text */
        }

        .philosophy-heading {
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .philosophy-text {
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 30px;
          color: #555; /* Slightly muted color for readability */
        }

        .btn-primary {
          background-color: #ff9800; /* Custom button color */
          border: none;
          padding: 10px 20px;
          font-size: 14px;
          letter-spacing: 1px;
        }

        @media (max-width: 768px) {
          .tc-philosophy-section {
            padding: 40px 0;
          }

          .philosophy-content {
            margin-top: 20px;
          }

          .philosophy-heading {
            font-size: 28px;
          }

          .philosophy-text {
            font-size: 16px;
          }

          .philosophy-image {
            width: 70%; /* Adjust width for smaller screens */
            max-width: 300px; /* Adjust max-width for smaller screens */
          }
        }
      `}</style>
    </section>
  );
}

export default PhilosophySection;
