import React, { useState } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import data from '../../../data/innerpages/portfolio/latestcases'; // Adjust the path as needed

Modal.setAppElement('#root'); // Set the root element for accessibility

const LatestCases = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProjectImages, setSelectedProjectImages] = useState([]);
  const [currentProjectTitle, setCurrentProjectTitle] = useState('');
  const [isZoomed, setIsZoomed] = useState(false); // For toggle zoom-in/zoom-out
  const [projectType, setProjectType] = useState('residential'); // Track project type

  const openModal = (images, title) => {
    setSelectedProjectImages(images);
    setCurrentProjectTitle(title); // Store project title
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedProjectImages([]);
    setCurrentProjectTitle(''); // Reset project title
    setIsZoomed(false); // Reset zoom state
  };

  // Slider settings for project images
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed); // Toggle zoom-in/zoom-out
  };

  // Filter projects based on selected type
  const filteredProjects = data.filter((project) =>
    projectType === 'residential'
      ? project.title === 'My Home Bhooja' || project.title === 'Aurobindo'
      : project.title !== 'My Home Bhooja' && project.title !== 'Aurobindo'
  );

  const styles = {
    section: {
      padding: '60px 0',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 20px',
    },
    title: {
      fontSize: '45px',
      textTransform: 'capitalize',
      marginBottom: '40px',
      textAlign: 'center',
    },
    toggleContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '40px',
    },
    toggleButton: {
      padding: '10px 20px',
      margin: '0 10px',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: '#333',
      color: 'white',
      fontSize: '16px',
      borderRadius: '5px',
    },
    activeButton: {
      backgroundColor: '#007BFF',
    },
    row: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(30%, 1fr))', // Responsive 3-column grid
      gap: '20px', // Space between items
    },
    column: {
      width: '100%', // Take full width of the grid cell
    },
    mainImage: {
      width: '100%', // Make sure image takes up full width
      height: '250px', // Set a fixed height for all images
      objectFit: 'cover', // Ensure images fill the area and are cropped uniformly
      cursor: 'pointer',
      borderRadius: '5px',
    },
    modal: {
      overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
      },
      content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: '80vh', // Square shape based on viewport height
        height: '80vh', // Square shape based on viewport height
        margin: 'auto',
        position: 'relative',
      },
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      border: 'none',
      color: 'black',
      fontSize: '30px',
      cursor: 'pointer',
    },
    modalTitle: {
      fontSize: '24px',
      marginBottom: '10px',
      textAlign: 'center',
      color: 'black', // Set project title color to black
    },
    modalImage: {
      width: isZoomed ? '160%' : '100%', // Zoom effect based on state
      height: 'auto',
      transition: 'transform 0.3s ease',
      cursor: isZoomed ? 'zoom-out' : 'zoom-in',
      maxHeight: isZoomed ? 'none' : '60vh',
      objectFit: 'cover',
    },
    sliderContainer: {
      width: '100%',
      maxWidth: '80vh',
      marginBottom: '20px',
    },
    zoomButton: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      border: 'none',
      padding: '10px',
      cursor: 'pointer',
      fontSize: '16px',
    },
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h3 style={styles.title}>Latest Projects</h3>
        
        {/* Toggle Buttons for Residential and Commercial */}
        <div style={styles.toggleContainer}>
          <button
            style={{
              ...styles.toggleButton,
              ...(projectType === 'residential' ? styles.activeButton : {}),
            }}
            onClick={() => setProjectType('residential')}
          >
            Residential
          </button>
          <button
            style={{
              ...styles.toggleButton,
              ...(projectType === 'commercial' ? styles.activeButton : {}),
            }}
            onClick={() => setProjectType('commercial')}
          >
            Commercial
          </button>
        </div>

        <div className="cases">
          <div style={styles.row}>
            {filteredProjects.map((project, index) => (
              <div key={index} style={styles.column}>
                <h4>{project.title}</h4>
                <div className="case-card">
                  <img
                    src={project.images[0]} // Display main image
                    alt={project.title}
                    style={styles.mainImage}
                    onClick={() => openModal(project.images, project.title)} // Open modal with project images and title
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal for displaying project images */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Images"
        style={styles.modal}
      >
        <button onClick={closeModal} style={styles.closeButton}>✖</button>
        <h2 style={styles.modalTitle}>{currentProjectTitle}</h2> {/* Project title in black */}
        
        <div style={styles.sliderContainer}>
          <Slider {...settings}>
            {selectedProjectImages.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Project image ${index + 1}`}
                  style={styles.modalImage}
                  onClick={toggleZoom} // Toggle zoom-in/zoom-out on image click
                />
              </div>
            ))}
          </Slider>
        </div>

        {/* Zoom in/out button */}
        <button onClick={toggleZoom} style={styles.zoomButton}>
          {isZoomed ? 'Zoom Out' : 'Zoom In'}
        </button>
      </Modal>
    </section>
  );
};

export default LatestCases;
