import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Service data with separate content for popup
const servicesData = [
  {
    title: 'Design Consultancy',
    description: 'Welcome to our realm of exceptional design and comprehensive solutions. Situated in Hyderabad, we are a renowned interior design firm known for our expertise in crafting tailor-made, luxurious interiors and exquisite furnishings.',
    content: `Our capabilities span a broad spectrum of design services, including complete corporate, commercial, retail, hospitality, and luxury residential interior design, interior architecture, project management, and bespoke furniture design. Our Areas of Expertise include:
    Luxury Interior Design: Whether it’s a cozy lounge or a sophisticated workspace, our specialty lies in creating opulent interiors that seamlessly fuse luxury with functionality.
    Project Management: Our strength lies in efficiently managing and delivering design projects tailored to the unique needs of our clients.
    Integrated Design: Our comprehensive integrated design service features in-house teams of experienced professionals, ensuring a holistic design process.`,
    icon: 'la la-hard-hat',
    image: '/innerpages/assets/img/services/ser1.jpg',
    delay: '0.2s',
  },
  {
    title: 'Building Construction',
    description: 'At CASA FORTUNE, we pride ourselves on offering comprehensive building construction services that fuse design, engineering, and craftsmanship through the expertise of our skilled professionals.',
    content: `Our distinctive approach involves meticulous attention to detail from concept to handover, emphasizing quality, safety, and sustainability. With a diverse portfolio spanning residential, commercial, industrial, and institutional projects, we consistently exceed expectations. Experience limitless construction possibilities with us, where excellence is the cornerstone of every project.`,
    icon: 'la la-bezier-curve',
    image: '/innerpages/assets/img/services/ser2.jpg',
    delay: '0.4s',
  },
  {
    title: 'Interior Design & Fit-out',
    description: 'Our interior designers interpret each client’s desires and style preferences to provide pioneering and exquisite interiors.',
    content: `Interior spaces are often completely re-imagined beyond the ornamental. Each of our projects is as individualized as a piece of art or couture. Our services include intelligent space planning, conceptual & detailed design, mechanical, electrical, and plumbing design (MEP), full approval packs, construction drawings, operation & maintenance manuals, and 3D rendering & visualization.`,
    icon: 'la la-bed',
    image: '/innerpages/assets/img/services/ser3.jpg',
    delay: '0.6s',
  },
  {
    title: 'Carpentry Work & Joinery',
    description: 'We specialize in Carpentry Work & Joinery with a unique blend of traditional craftsmanship and contemporary innovation.',
    content: `Our mastery is evident in the precision and artistry we bring to every project, crafting custom woodwork that reflects your style and providing tailored joinery solutions for homes, offices, and commercial spaces.`,
    icon: 'la la-comments',
    image: '/innerpages/assets/img/services/ser4.jpg',
    delay: '0.8s',
  },
  {
    title: 'MEP Design & HVAC',
    description: 'Our core specialization lies in MEP (Mechanical, Electrical, and Plumbing) design and execution, providing extensive services tailored to address the multifaceted demands of projects.',
    content: `We conduct in-depth analyses, develop designs that optimize functionality, and create accurate 3D models. Our services also include equipment selection, method statements, planning and coordination, and as-built drawings. HVAC systems control the temperature and humidity, ensuring safe and healthy indoor air quality.`,
    icon: 'la la-comments',
    image: '/innerpages/assets/img/services/ser5.jpg',
    delay: '1.0s',
  },
  {
    title: 'Turnkey Solutions',
    description: 'We offer turnkey solutions, managing every aspect of your project from civil work to furniture installation.',
    content: `This comprehensive approach streamlines the construction process, ensuring timely completion and adherence to budget. We provide a single point of contact for your project, coordinating all contractors and specialists for a cohesive end result.`,
    icon: 'la la-comments',
    image: '/innerpages/assets/img/services/ser6.jpg',
    delay: '1.2s',
  },
  {
    title: 'Furniture Design',
    description: 'Our Furniture Design service focuses on creating custom, bespoke furniture pieces that complement the aesthetics and functionality of any space.',
    content: `From concept to creation, our skilled designers and craftsmen work closely with clients to design furniture that reflects personal style and enhances the overall interior environment. Each piece is crafted with attention to detail, ensuring a perfect balance of form, function, and luxury, whether for residential or commercial spaces.`,
    icon: 'la la-couch',    image: '/innerpages/assets/img/services/ser7.jpg',
    delay: '1.2s',
  },
  {
    title: 'Commercial Kitchen Design & Build',
  description: 'We specialize in designing and building efficient, high-performance commercial kitchens tailored to the unique needs of the food service industry.',
  content: `Our team provides end-to-end solutions, from layout planning to equipment selection, ensuring optimal workflow and compliance with industry standards. We prioritize durability, hygiene, and functionality, making sure every kitchen is equipped to handle the demands of busy commercial environments while maintaining a stylish, professional appearance.`,
  icon: 'la la-utensils',
  image: '/innerpages/assets/img/services/ser8.jpg',
    delay: '1.2s',
  },
];


function Services() {
  const [showModal, setShowModal] = useState(false);
  const [activeService, setActiveService] = useState({});

  const handleShow = (service) => {
    setActiveService(service);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          
          <div className="services">
            <div className="row">
              {servicesData.map((service, index) => (
                <div className="col-lg-4" key={index}>
                  <a
                    href="#"
                    className={`service-card wow fadeInUp ${index % 3 !== 2 ? 'mt-10' : ''}`} // Only add margin if it's not the last card in the row
                    data-wow-delay={service.delay}
                  >
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                    <h5 className="fsz-24 mb-20">{service.title}</h5>
                    <div className="img">
                      <img
                        src={service.image}
                        alt={service.title}
                        className="img-cover"
                      />
                    </div>
                    <div className="text color-666 mt-50">
                      {service.description}
                    </div>
                    <span
                      className="arrow"
                      onClick={() => handleShow(service)}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="ti-arrow-top-right"></i>
                    </span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center">
            <a
              href="/innerpages/contact"
              className="butn rounded-pill mt-80 hover-bg-black bg-orange1 text-white"
            >
              <span>
                Get A Free Quote Now
                <i className="small ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      

      {/* Modal for Service Details */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{activeService.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{activeService.content}</p>
          <img src={activeService.image} alt={activeService.title} className="img-cover w-100" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Services;
