import React from 'react';

function Navbar3() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light inner-navbar-style1" style={{ background: 'rgba(255, 255, 255, 0)', zIndex: 100, position: 'absolute', width: '100%' }}>
      <div className="container-fluid">
        <a className="navbar-brand me-lg-5" href="/">
          <img src="/innerpages/assets/img/final.png" alt="" className="logo" style={{ maxWidth: '150px', height: 'auto' }} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/" style={{ color: '#000', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(255, 255, 255, 0.5)' }}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/about" style={{ color: '#000', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(255, 255, 255, 0.5)' }}>
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/services" style={{ color: '#000', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(255, 255, 255, 0.5)' }}>
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/portfolio" style={{ color: '#000', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(255, 255, 255, 0.5)' }}>
                Projects
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/innerpages/contact" style={{ color: '#000', fontWeight: 'bold', textShadow: '1px 1px 2px rgba(255, 255, 255, 0.5)' }}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar3;
